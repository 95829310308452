import React from "react";
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import CategoryTemplate from "../../../templates/category-page";

const coins = [
  "uniswap",
  "thorchain",
  "osmosis",
  "pancakeswap-token",
  "havven",
  "loopring",
  "curve-dao-token",
  "sushi",
  "gnosis",
  "1inch",
  "bancor",
  "0x",
  "dydx",
  "perpetual-protocol",
  "raydium",
  "dodo",
  "balancer",
  "ellipsis",
  "apeswap-finance",
  "openocean",
];
const title = "Dezentrale Börsen (DEX)";
const Page: React.FC = () => {
  return (
    <Layout>
      <SEO keywords={["Dezentrale Börsen", "dex"]} title={title} />
      <CategoryTemplate title={title} coins={coins} coingeckoId="decentralized-exchange" />
    </Layout>
  );
};

export default Page;
